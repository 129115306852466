
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ProductionCountries from './productionCountries.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ProductionCountries
    }
})
export default class BestCombinationTable extends Vue {

    @Prop({
        default: () => new OM.BestCombination()
    }) model: OM.BestCombination;

    created() {
    }

}
